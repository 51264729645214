.sidebar-enter {
    opacity: 0;
    transform: scale(0.9);
    margin-left: 0% !important;
    transition: margin-left 300ms;
  }
  .sidebar-enter-active {
    opacity: 1;
    transform: translateX(0);
    margin-left: 0 !important;
    transition: opacity 300ms, transform 300ms, margin-left 300ms;
  }
  .sidebar-exit {
    margin-left: -256px !important;
    transform: scale(0.9);
    transition: opacity 300ms, margin-left 300ms, transform 1ms;
  }
  .sidebar-exit-done {
    margin-left: -256px !important;
    transform: scale(0.9);
    transition: opacity 300ms, margin-left 300ms, transform 1ms;
  }

  /* Main site body */
.wrapper {
  min-width: 302px;
  position: relative; /* Ensure absolute positioned child elements are relative to this*/
}

/* Absolute positioned wrapper for the element you want to fix position */
.fixed-wrapper {
  position: absolute;
}

/* The element you want to fix the position of */
.fixed {
  position: fixed;
  /* Do not set top / left! */
}
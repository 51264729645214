.table-create-package-one{
    th, td{
        padding: 32px 0px;
        font-style: normal;
        font-size: 14px;
        line-height: 100%;       
    }

    th{
        font-weight: 600;
        color: #B2B3BD;
        border: none;
    }
    td{
        font-weight: 500;
        color: #11142D;
    }

    tr{
        border-bottom: 1px solid #E4E4E4;
    }
}
.table-create-package-preview{
    th, td{
        padding: 32px 0px;
        font-style: normal;
        font-size: 14px;
        line-height: 100%;       
    }

    th{
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
    }
    td:nth-child(3){
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        color: #11142D;
        text-align: right;
    }
    td:nth-child(2){
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #B2B3BD;
    }

    td{
        div{
            margin: 21px 0;
        }
    }

    tr{
        border-bottom: 1px solid #E4E4E4;
    }
}
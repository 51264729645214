.plan-info-page{

    .title-bar{
        width: 100%;
        .row{
            width: 100%;
        }

        .plan-name{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            margin-top: 12px;
            margin-bottom: 17px;
            color: #11142D;
        }

        .tags div{
            margin-right: 20px;
        }
    }

    .stats-table{

        .title{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #B2B3BD;
            border: none;
            margin-bottom: 10px;
        }

        .description{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            border: none;
            color: #11142D;

        }
    }

    .rows-container{
        padding: 87px 192px;
        padding-right: 60px;
    }

    .investiment-section{
        margin-top: 157px;
    }

    .feature-box{
        height: 730px;

        .row{
            width: 100%;
        }
        .title{
            margin-top: 137px;
            margin-right: 16%;
        }

        .feature-points{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #11142D;
            margin-top: 85px;
            text-decoration: none;
            list-style: none;
            .highlited{

            }
        }
    }

    .house-tour-box{
        margin-top: 307px;
        margin-bottom: 290px;
        .title{
            margin-top: 13px;
        }
        .action-button{
            display: block;
            margin: 0 auto;
            margin-top: 38px;
            margin-bottom: 67px;
        }

        img{
            max-width: 756px;
            width: 100%;
        }
    }

    .location-box{
        .row{
            width: 100%;
        }
        img{
            max-width: 766px;
            width: 100%;
        }

        .title{
            margin-top: 55px;
        }

        .feature-points{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #11142D;
            margin-top: 30px;
            padding-left: 20px;
        }
    }

    .footer-note-box{
        width: 100%;
        margin-top: 144px;
        margin-bottom: 95px;
        .note{
            margin: 0 auto;
            max-width: 631px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.01em;
            color: #848484;
        }

        
    }

    .image-container{
        position: relative;
        .img-secondary{
            box-shadow: inset 56px 0px 60px 33px white;
            position: absolute;
            width: 104px;
            top: 215px;
            height: 100%;
        }
    }

}

.back-to-top-box{
    position: relative;

    button{
        position: absolute;
        right: 140px;
        bottom: 111px;
    }
}

.justify-start{
    tr{
        width: 573px;
    }
    
}

html {
    scroll-behavior: smooth;
}

.img-shadow{
    -webkit-box-shadow: inset 56px 0px 61px 6px rgba(255,255,255,1);
    -moz-box-shadow: inset 56px 0px 61px 6px rgba(255,255,255,1);
    box-shadow: inset 56px 0px 61px 6px rgba(255,255,255,1);
    position: absolute;
}
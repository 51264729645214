.welcome-wrapper-new{
    .content-wrapper{
        margin-top: 141px;

        .badges-wrapper{
            >div:nth-child(2){
                margin-left: 50px;
            }
            >div:nth-child(3){
                margin-left: 50px;
            }
        }
    }

    .page-info-wrapper{
        margin-top: 120px;
        margin-left: 10%;
    }

    @keyframes backgroundColorPalette {
        0% {
            background: #6955ee;
        }
        20% {
            background: #0ece65;
        }
        40% {
            background: #27a8ed;
        }
        60% {
            background: #e3a922;
        }
        80% {
            background: #04a3d3;
        }
        100% {
            background: #04bed3;
        }
    }

    @keyframes backgroundImages {
        0% {
            width: 250px;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 55%;
        background-repeat: no-repeat;
        background-size: contain;
            background-image: url('../../assets/images/av-sales-login-purple.svg');
        }
        20% {
            width: 250px;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 55%;
        background-repeat: no-repeat;
        background-size: contain;
            background-image: url('../../assets/images/av-sales-login-green.svg');
        }
        40% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 55%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-blue-1.svg');
        }
        70% {
            width: 250px;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 55%;
        background-repeat: no-repeat;
        background-size: contain;
            background-image: url('../../assets/images/av-sales-login-org.svg');
        }
        80% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 55%;
            background-repeat: no-repeat;
            background-size: contain;
                background-image: url('../../assets/images/av-sales-login-blue.svg');
        }
        100% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 55%;
            background-repeat: no-repeat;
            background-size: contain;
                background-image: url('../../assets/images/dave.svg');
        }
    }
    
}
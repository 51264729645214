.plan-card{
    .card-image{
        vertical-align: middle;
    }

    .info-area{
        display: inline-block;
        margin-left: 21px;
        vertical-align: middle;

        .title-box{
            display: inline-block;
        }

        .info-tags{
            >span:nth-child(2){
                margin: 0 10px;
            }
        }
    }
}
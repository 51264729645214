.image-viewer{
    &::after{
            content:'';
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 10;
            position: absolute;
            -webkit-box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
            -moz-box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
            box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
    
    }

    .pnlm-container{
        pointer-events: all;
    }

    button{
        pointer-events: all;
    }

    .pnlm-about-msg{
        display: none !important;
    }
}
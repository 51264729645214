.slide-sheet-box{
    position: fixed;
    bottom: 0;
    width: 61.3%;
    height: 100%;
    z-index: 100;
    box-shadow: 20px 0px 20px 0px rgba(95, 95, 95, 0.1);
    overflow: scroll;
}

.slide-sheet-box{
    background-color: #ffffff;

    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #11142D;
    }

    .content-container{
        // padding: 0 43px;
    }
}

.slide-sheet-wrapper{
    // padding: 23px 68px;
}
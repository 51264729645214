.login-page-wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #E5E5E5;
    .round-wrapper{
        background: #FFFFFF;
        border-radius: 40px;
        max-width: 922px;
        margin: 0 auto;
        margin-top: 195px;
        // display: block;
        padding: 0px 57px;
        margin-bottom: 38px;

        .row{
            > div{
                height: 90px;
            }
        }
    }
    .round-wrapper-login{
        position: relative;
        left: 20%;
        background: #FFFFFF;
        border-radius: 40px;
        max-width: 598px;
        margin: 0 auto;
        margin-top: 195px;
        // display: block;
        padding: 0px 57px;
        margin-bottom: 127px;

        .row{
            > div{
                height: 90px;
            }
        }
    }

    .title-desc{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #C7C7C7;


        &.dark{
            color:#848484;
        }
    }

    .input-wrapper{
        label{
            display: block;
            text-align: left;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #808191;
            margin: 0 auto;
            margin-left: 25px;
        }

        input{
            text-align: left;
            display: block;
            background: rgba(228, 228, 228, 0.49776);
            border: 2px solid #E3F3F8;
            border-radius: 8px;
            padding: 18px 23px;
            width: 90.7%;
            margin: 0 auto;
            margin-top: 18px;
            max-width: 417px;
            margin-bottom: 18px;
        }
    }

    .form-group{
        text-align: center;
        margin-top: -83px;
        button{
            
        }
    }

    .signup-instructions{
        margin-bottom: 47px;
        display: inline-block;
    }

    .logo{
        img, svg{
            margin-top: 68px;
        }
    }

    .top-actions{
    
        .button-right{
            position: fixed;
            right: 7%;
            top: 62px;
        }
    }
}
.full-sheet-box{
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    overflow: scroll;
    box-shadow: 20px 0px 20px 0px rgba(95, 95, 95, 0.1);
}

.full-sheet-box{
    background-color: #ffffff;

    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #11142D;
    }
    .land-name{
        max-width: 277px;
        margin-top: 35px;
        margin-bottom: 20px;
    }

    .content-header{
        .title-box{
            padding: 33px 0;

            .action-group{
                float: right;
            }
        }
    }

    .content-container{
        // padding: 20px 50px;
    }
}

.full-sheet-wrapper{
    // padding: 23px 68px;
}
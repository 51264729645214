$btn-primary-color: #fff;
$primary: #41BCFE;
$secondary: #F6F6F6;
$success: #7FBA7A;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
font-family: 'Inter';
src: local('Inter'), url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype');
}

*{
    font-family: Inter;
}

.btn{
    border-radius: 6px;
}

.btn-primary {
    @include button-variant($primary, $primary, #ffffff);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 14px 17px;
}

.btn-primary-lg {
    @extend .btn-primary;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.2%;
    padding: 14px 28px;
}

.btn-primary-lg-c {
    @extend .btn-primary-lg;
    line-height: 35px;
    padding: 7px 20px;
}

.btn-secondary {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 14px 17px;
}

.btn-success {
    @include button-variant($success, $success, #ffffff);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 8px 24px;
    border-radius: 4px;
}

#sidebar{
    // width: 370px;
    min-width: 302px;
    min-height: 100vh;
    // margin-left: -21%;
    border-right: 1px solid #dee2e6!important;
    background-color: #fff;
    box-shadow: 10px 10px 15px rgba(37, 93, 125, 0.02);
}

#sidebar .sidebar-heading {
    padding: 34px 1.25rem;
    font-size: 1.2rem;
    border-bottom: 2px solid rgb(178 179 189 / 0.1);
}

#sidebar .list-group {
    width: 100%;
}
.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

#sidebar{
    .active-nav{
        background-color: rgba($primary, 0.08);
        // border-left: 5px solid $primary;
        box-shadow: inset 5px 0px 0px -3px $primary;
    }
    .list-group-item{
        padding: 19px 2rem;

        .nav-name{
            vertical-align: baseline;
            margin-left: 20px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 132.2%;
        }
    }

    .toggle-content{
        background-color: #FAFAFA;
        padding: 45px 35px;
    }
}

.content{
    min-width: 0;
    width: 100%;
    position: relative;
    // padding: 0 50px;

    .content-header{
        border-bottom: 2px solid rgb(199, 199, 199, 0.1);
        padding: 0px 43px;
        .title-box{
            padding: 33px 0;
            .action-group{
                float:right;  
                .btn-main{
                    margin-left: 3vw;
                }
            }
        }
    }
    .page-content{
        padding: 20px 50px;
    }
}

.user-info{
    vertical-align: middle;
    margin-left: 12px;

    .user{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #11142D;
    }
    .role{
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #848484;
    }
}

.btn-circle-sm{
    width: 32px;
    height: 31px;
    text-align: center;
    line-height: 0px;
    padding: 0;
}

.sidebar-toggle{
    float: right;
    margin-top: 3px;
    margin-right: -16px;
}

.nav-footer{
    text-align: center;
    position: fixed;
    bottom: 22px;
    left: 30px;

    span{
        font-size: 8px;
        float: right;
        text-align: left;
        margin-left: 13px;
    }
}

.text-dg{
    color: #808191;
}

.text-lg{
    color: #B2B3BD;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
}

.text-lg-sm{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 12px;
color: #B2B3BD;
}

.text-mg{
    color: #6D777F;
}

.text-dbg{
    color: #252A2F;
}

.text-pd{
    color: #11142D;
}

.small-title{
    color: #808191;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.medium-title{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #11142D;
}

.col-md-3-5{
    @extend .col-md-3;
    min-width: 350px;
    max-width: 350px;
    width: 40%;
}


@media (max-width: 360px) {
    #sidebar {
        position: absolute;
        z-index: 10000;
    }
}


.slide-in {
    position: initial;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    position:absolute;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-in {
    0% { transform: translateX(0%); }
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    0% { transform: translateX(0%); }
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-90%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-90%); }
}

hr{
    width: 100%;
    opacity: 0.3;
    border: 1px solid #C7C7C7;
}

.sort-by-land-b-l{
    background: #FDFDFD;
    box-shadow: 0px 21px 47px -14px rgba(102, 102, 102, 0.04);
    overflow: hidden;
    .outer-wrapper{
        width: 20vw;
    }
    .box{
        margin-top: 40px;
        margin-bottom: 22px;
        .wrapper{
            margin-top: 35px;
            .label{
                max-width: 85px;
                display: inline-block;
                vertical-align: middle;
            }
            .input-group-i input{
                height: 42px;
                background: rgba(228, 228, 228, 0.3);
                border: 2px solid #E4E4E4;
                border-radius: 6px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #6A6A6A;
                display: inline-block;
                outline: none;
                padding: 0px 15px 0px 15px;
                max-width: 140px;
            }
            .search-group{
                input{
                    height: 42px;
                    background: #FFFFFF;
                    opacity: 0.9;
                    border-radius: 6px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #11142D;
                    border: none;
                    outline: none;
                    width: 80%;
                    padding: 0 15px;
                }

                .icon{
                    position: relative;
                    left: -35px;
                }
            }
            input.active{
                
            }
            :nth-child(2){
                margin-top: 8px;
            }
        }
    }
    &>div > div:nth-child(2){
        border-right: 1px solid rgba(199, 199, 199, 0.3);
        border-left: 1px solid rgba(199, 199, 199, 0.3);
        padding-left: 5vw;
        padding-right: 5vw;
    }
    &>div > div:nth-child(3){
        // padding-left: 3vw;
    }
    
}

.sort-result{
    header{
        .title{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 144%;
            color: #7F8082;
            display: inline-block;
        }
        .actions{
            float: right;
        }
    }
}

.line-color{
    height: 0px;
    left: 672px;
    top: 378px;
    border: 1px solid rgba(199, 199, 199, 0.3);
    transform: rotate(90deg);
}

.c-table{
    th{
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #B2B3BD;
        padding: 20px;
    }
    tbody{
        cursor: pointer;
        tr:hover{
            background-color: #f9f9f9;
        }
    }
    td{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 122%;
        color: #11142D;
        vertical-align: middle;
        padding: 20px;

        &.img-location{
            img{
                width: 77px;
                margin-right: 21px;
                border-radius: 50%;
            }
        }
    }
    .selection{
        input{
            border-radius: 4px;
            padding: 0;
            width: 20px;
            height: 20px;
        }
        input:checked{
            background-color: #6C5DD3 !important;
        }

    }
}

.land-details-box{
    .title-second{
        margin-top: 30px;
    }
    .left-bar{
        // max-width: 300px;
        display: inline-block;
        padding: 0px 1px 60px 56px;

        ul{
            margin-top: 30px;
            display: block;
        }
    }
    .c-table{
        max-width: 300px;
        display: inline;

        th{
            border: none;
        }
    }
}


.sorting-box{
    .sort-by-plans{
        .small-title{
            margin-bottom: 22px;
        }
        .switcher{
            clear: both;
        }
    }

    
}

.sort-result{
    .listing{
        width: 100%;
        margin-top: 40px;
    }
}

.land-details-box{
    .img-wrapper{
        max-width: 438px;
    }
    .land-name{
        margin-left: 38px;
    }
    .land-info-title{
        margin: 29px 0;
        margin-left: 38px;
    }
}


.default-checkbox{
    border-radius: 4px;
    padding: 0;
    width: 20px;
    height: 20px;
    box-shadow: none;

    &:checked {
        background-color: #6c5dd3!important;
        border: none;
        box-shadow: none;
    }
}

.min-loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .checkbox{
    border-radius: 4px;
    padding: 0;
    width: 20px;
    height: 20px;
  }
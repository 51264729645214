.circles{
    text-align: center;
    display: flex;
    justify-content: center;
    svg{
        display: inline-block;
        width: 90px;
    }

    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 132.2%;
        /* identical to box height, or 19px */

        text-align: center;

        /* text/active */

        color: #11142D;

        margin-top: 16px;
        margin-bottom: 3px;
    }

    .desc{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        /* identical to box height, or 12px */

        text-align: center;

        /* Color/Dark - Grey - Neutral/DEACTIVE */

        color: #808191;
    }
}
.btn-c-xs{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

.toggle-switch{
    
   
}
